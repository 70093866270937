import axios from "axios";
import { Loading } from "element-ui"

const area=process.env.VUE_APP_AREA;
const env=process.env.VUE_APP_ENV;
let urlRecord:Record<string,object>={
   gd:{
    bd:"http://localhost:8132/",
    // qa:"http://qa.maintain.giiatop.com/",
    qa:"https://qa.giiatop.com/maintain/",
    tp:"http://tp.guangdongzj.maintain.giiatop.com/",//tp广东中介运维
    // live: "https://platform.gaii.org.cn/maintain/", //广东（产寿）
    live: "http://zj.guangdong.maintain.giiatop.com/", //广东（中介）
   },
   sd:{
    bd:"http://localhost:8132/",
    qa:"http://qa.maintain.giiatop.com/",
    live:"http://60.216.62.188:10263/"
   },
   sc:{
    bd:"http://localhost:56187/",
    qa:"http://qa.maintain.giiatop.com/",
    live:"http://139.199.48.196:8081/"
   },
};
//const baseURL: any =(urlRecord[area] as Record<string,string>)[env];
const baseURL:any = "/"
axios.defaults.baseURL = "/"
axios.defaults.timeout = 100000
axios.defaults.withCredentials = true
axios.defaults.validateStatus = status => {
    return status >= 200 && status < 400
};
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.request.use(config => {
    return config
});
axios.interceptors.response.use(response => {
    return response.data
}, reason => Promise.reject(reason))

export default {
    /**
     * @param {string} url:  请求地址
     * @param {any} data：请求参数
     * @param {any} showLoading：是否显示loading
     * @param {string} loadingMsg： loading提示信息
     * @param {any} config: 配置项
     */
    post(url: string, data: any, showLoading: any = false, loadingMsg: string = "数据正在加载中，请耐心等待......", config: any = {}): Promise<void> {
        let loading: any = ""
        if (showLoading) {
            loading = Loading.service({
                lock: true,
                text: loadingMsg,
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        }
        return axios.post(url, data, config).then((res: any) => {
            const code: number = +res.code
            if (showLoading) {
                loading.close()
            }
            return res
        }).catch(err => {
            if (showLoading) {
                loading.close()
            }
            console.warn(err)
        })
    },
    domain() {
        return baseURL
    }
}